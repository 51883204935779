// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

@use '../abstracts/mixins' as *;

.site-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: var(--padding);
        padding: 1em var(--padding);

        @include devices(mobile) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__logo {
        display: flex;
        max-width: 100%;

        img {
            min-width: 50px;
            max-width: 100px;
        }
    }

    &__navigation {
        display: flex;
        align-items: center;
        font-family: var(--ff-regular);
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: var(--padding);
            list-style: none;
            padding: 0;
            margin: 0;
            justify-content: center;

            @include devices(mobile) {
                justify-content: flex-start;
            }

            li {
                a {
                    position: relative;
                    color: var(--primary);
                    text-decoration: none;
                    text-transform: uppercase;
                    @include letter-spacing(100);

                    &::before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 1px;
                        bottom: 0;
                        left: 0;
                        background-color: #000;
                        transform: scaleX(0);
                        transform-origin: left;
                        transition: transform 0.3s ease;
                    }

                    &:hover::before {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}
