@use '../abstracts/mixins' as *;

.login-page {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        @include min-vh(100);

        .card {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 100%;
            &__form {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;
                width: 250px;
            }

            &__logo {
                display: flex;
                justify-content: center;
                margin-bottom: 2em;

                img {
                    max-width: 250px;
                }
            }

            &__subline {
                font-family: var(--ff-regular);
                color: var(--white);
                text-transform: uppercase;
                @include letter-spacing(200);
                text-align: center;
                margin-bottom: 2em;

                span {
                    font-size: 1rem;
                    @include letter-spacing(100);
                }

                &::after {
                    display: block;
                    content: '';
                    position: relative;
                    height: 75px;
                    width: 1px;
                    background-color: var(--white);
                    margin: 1em auto 0 auto;
                }
            }

            p {
                color: var(--white);
            }
        }

        .footer-logo {
            margin-top: 2em;
            img {
                max-width: 100px;
            }
        }
    }
}
