// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

@use 'mixins' as *;

:root {
  --max-width: 100%;
  --padding: 1em;

  @include devices(tablet) {
    --padding: 1em;
  }

  @include devices(desktop) {
    --padding: 2em;
  }

  --ff-regular: 'Gotham-Book', sans-serif;
  --ff-baron: 'BaronMTModernCyr-Regular', serif;
  --ff-baron-italic: 'BaronMTModernCyr-Italic', serif;

  --primary: #000;
  --secondary: #eff6f8;
  --white: #fff;
  --gray: #f4f4f4;

  --cursor: #ffffff44;
}
