@use '../abstracts/mixins' as *;

.cursor {
    display: none;
}

.cursor-grow {
    display: none;
}

@include devices(tablet) {
    .cursor {
        display: block;
        width: 0px;
        height: 0px;
        border-radius: 50%;
        background-color: var(--cursor);
        backdrop-filter: blur(10px);
        position: fixed;
        transform: translate(-50%, -50%);
        pointer-events: none;
        z-index: 1001;
        transition: all 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
        transform-origin: 100% 100%;
        transition-property: background-color, transform, width, height;

        &__inner {
            display: grid;
            place-items: center;
            width: 100%;
            height: 100%;
            opacity: 1;

            img {
                width: 2.5em;
                height: 2.5em;
            }
        }
    }

    .cursor-grow {
        width: 120px;
        height: 120px;
        backdrop-filter: blur(5px);
    }
}
