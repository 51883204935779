@use '../abstracts/mixins' as *;

.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    span {
        font-family: var(--ff-regular);
        font-size: 16px;
        color: var(--primary);
        text-transform: uppercase;
        @include letter-spacing(200);
    }

    &--hidden {
        opacity: 0;
        pointer-events: none;
    }
}
