// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

@use '../abstracts/mixins' as *;

.home-page {
    background-color: #eff6f8;
    .hero {
        position: relative;
        display: grid;
        place-items: center;
        z-index: -1;

        &__video {
            position: relative;
            object-fit: cover;
            @include min-vh(100);
        }

        &__icon {
            position: absolute;
            bottom: 0.5em;
            width: 50px;
            margin: 0 auto;
        }

        &__title {
            position: absolute;
            font-family: var(--ff-baron);
            font-size: clamp(3.75rem, 2.625rem + 5.625vw, 9.375rem);
            text-transform: uppercase;
            line-height: 0.9;
            text-align: center;
            color: var(--white);

            span {
                font-family: var(--ff-baron-italic);
                font-size: clamp(1.875rem, 1rem + 4.375vw, 6.25rem);
                line-height: 0.9;
                text-transform: lowercase;
            }
        }

        .video-overlay {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            animation: fadeIn 1s ease-in-out forwards;
            opacity: 0;

            @keyframes fadeIn {
                to {
                    opacity: 1;
                }
            }

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .download {
        position: relative;
        display: grid;
        place-items: center;
        @include min-vh(100);
        pointer-events: none;

        &__container {
            position: absolute;
            top: 0;
            width: 100%;
            @include vh(100);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--padding);
        }

        .download-item {
            display: flex;
            column-gap: 1em;
            text-decoration: none;
            color: var(--primary);
            z-index: 1;
            pointer-events: all;

            &__title {
                font-family: var(--ff-baron);
                font-size: clamp(3.75rem, 2.625rem + 5.625vw, 9.375rem);
                text-transform: uppercase;
                line-height: 0.9;
                text-align: center;

                span {
                    font-family: var(--ff-baron-italic);
                    font-size: clamp(1.875rem, 1rem + 4.375vw, 6.25rem);
                    text-transform: lowercase;
                }
            }
        }
    }

    .gallery {
        position: relative;
        display: grid;
        grid-template-columns: var(--padding) repeat(12, 1fr) var(--padding);
        row-gap: 4em;
        padding: 4em 0;

        &__item:nth-child(1) {
            grid-column: 3 / 13;
        }
        &__item:nth-child(2) {
            grid-column: 4 / 12;
        }
        &__item:nth-child(3) {
            grid-column: 3 / 13;
        }
        &__item:nth-child(4) {
            grid-column: 4 / 12;
        }
        &__item:nth-child(5) {
            grid-column: 3 / 13;
        }
        &__item:nth-child(6) {
            grid-column: 4 / 12;
        }

        @include devices(mobile) {
            padding: var(--padding) 0;

            &__item:nth-child(1) {
                grid-column: 1 / 5;
                margin-top: 8em;
            }
            &__item:nth-child(2) {
                grid-column: 12 / 15;
            }
            &__item:nth-child(3) {
                grid-column: 8 / 11;
            }
            &__item:nth-child(4) {
                grid-column: 3 / 5;
            }
            &__item:nth-child(5) {
                grid-column: 10 / 14;
                margin-top: 8em;
            }
            &__item:nth-child(6) {
                grid-column: 4 / 7;
            }
        }
    }
}
