// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'BaronMTModernCyr-Italic';
    src: url('../../assets/fonts/BaronMTModernCyrItalic.woff2') format('woff2'),
        url('../../assets/fonts/BaronMTModernCyrItalic.woff') format('woff'),
        url('../../assets/fonts/BaronMTModernCyrItalic.ttf') format('ttf');
}

@font-face {
    font-family: 'BaronMTModernCyr-Regular';
    src: url('../../assets/fonts/BaronMTModernCyr-Regular.woff2') format('woff2'),
        url('../../assets/fonts/BaronMTModernCyr-Regular.woff') format('woff'),
        url('../../assets/fonts/BaronMTModernCyr-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Gotham-Book';
    src: url('../../assets/fonts/Gotham-Book.woff2') format('woff2'),
        url('../../assets/fonts/Gotham-Book.woff') format('woff'),
        url('../../assets/fonts/Gotham-Book.otf') format('opentype');
}
