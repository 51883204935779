// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

@use '../abstracts/' as *;

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */

body {
  background-color: white;
}

.site-main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  z-index: 1;
}

// Container
.container {
  width: 100%;
  max-width: var(--max-width);
  padding: var(--padding);
}

.fluid {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 0;

  img {
    object-fit: cover;
  }
}

img,
video {
  width: 100%;
  max-width: 100%;
}
