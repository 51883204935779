// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

@use '../abstracts/mixins' as *;

.site-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    isolation: isolate;
    padding: 1em var(--padding);
    // mix-blend-mode: difference;

    &__logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            img {
                width: 100px;
            }
        }
    }
}
