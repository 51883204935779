/**
 * Basic typography style for copy text
 */

@use '../abstracts/mixins' as *;

body {
  font-size: clamp(1.125rem, 1.0781rem + 0.2344vw, 1.3125rem);
  font-family: var(--ff-regular);
  line-height: 1.5;
  color: var(--primary);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;

  @include devices('mobile') {
    hyphens: none;
    -moz-hyphens: none;
    -webkit-hyphens: none;
    -ms-hyphens: none;
  }
}

.ff-regular {
  font-family: var(--ff-regular);
}

.ff-baron {
  font-family: var(--ff-baron);
}

.ff-baron-italic {
  font-family: var(--ff-baron-italic);
}

.centered {
  text-align: center;
}

.right {
  text-align: right;
}
